export function isBoolean(val) {
  return val === false || val === true;
}

export const ROLE = {
  ADMIN: 1,
  USER: 2,
};

export const USER_ROLE = [
  {
    value: 1,
    name: "Admin",
  },
  {
    value: 2,
    name: "User",
  },
];

export const DOCUMENTS_TYPE = [
  {
    value: 1,
    name: "AGREEMENT",
  },
  {
    value: 2,
    name: "BILL",
  },
];
export const MANUAL_CATEGORIES = [
  {
    value: 1,
    name: "WPROWADZENIE",
  },
  {
    value: 2,
    name: "PODSTAWA SPRZEDAŻY",
  },
  {
    value: 3,
    name: "SPRZEDAŻ",
  },
  {
    value: 4,
    name: "ZASTRZEŻENIA",
  },
  {
    value: 5,
    name: "PROWADZENIE KLIENTA",
  },
  {
    value: 6,
    name: "LICYTACJĘ / AUTA",
  },
  {
    value: 7,
    name: "KALKULACJĘ / SWIFT",
  },
  {
    value: 8,
    name: "LOGISTYKA / ODPRAWA",
  },
  {
    value: 9,
    name: "OWM - ODZIAŁ WSPARCIA MANAGERA",
  },
  {
    value: 10,
    name: "POLECENIA",
  },
];

export const Permissions = {
  [ROLE.ADMIN]: {
    users_page: true,
    documents_page: true,
    calculator_page: true,
    can_create_user: true,
    can_create_manual: true,
    can_update_remove_manuals: true,
    can_documents_user_filter: true,
    can_change_agreement_price: true,
    can_change_company_service_price: true,
  },
  [ROLE.USER]: {
    calculator_page: true,
    documents_page: true,
  },
};

export function buildFilterString(query) {
  //, omit=[]) {
  let result = "";
  for (let key in query) {
    // if (key in omit) {
    result +=
      query[key] !== null && query[key] !== undefined && query[key] !== ""
        ? `&${key}=${query[key]}`
        : "";
    // }
  }
  return result.substring(1, result.length);
}

export function changeUrlQuery(query) {
  const currentUrl = `${window.location.origin}${window.location.pathname}`;
  const newUrl = `${currentUrl}?${buildFilterString(query)}`;
  history.replaceState(null, "", newUrl);
}

export function objectFromQueryString() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  const paramsObject = {};
  params.forEach((value, key) => {
    paramsObject[key] = value;
  });
  return paramsObject;
}
